import React from 'react'
import { Link, useParams } from "react-router-dom"

import Chapter0 from './0.mdx'
import Chapter1 from './1.mdx'
import Chapter2 from './2.mdx'
import Chapter3 from './3.mdx'

Chapters = [Chapter0, Chapter1, Chapter2, Chapter3]

export default Guides = ->
  params = useParams()
  index = params['*']
  if index.length > 0
    index = parseInt index
  else
    index = 0
  Chapter = Chapters[index]
  <div className="hero min-h-screen bg-base-200">
    <div className="hero-content flex-col lg:flex-row">
      <div>
        <Chapter />
        <p className='py-3' />
        {
          if index > 1
            <Link to="/guides/#{index - 1}">
              <button className="btn btn-primary btn-outline">Prev</button>
            </Link>
          else if index is 1
            <Link to="/guides">
              <button className="btn btn-primary btn-outline">Prev</button>
            </Link>
          else
            <Link to="/">
              <button className="btn btn-primary btn-outline">Prev</button>
            </Link>
        }
        <span> </span>
        {
          if index < 3
            <Link to="/guides/#{index + 1}">
              <button className="btn btn-primary">Next</button>
            </Link>
          else
            <a href="https://joinus.community">
              <button className="btn btn-primary">JOINUS.COMMUNITY</button>
            </a>
        }
      </div>
    </div>
  </div>
