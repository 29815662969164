import React from 'react'
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import {MDXProvider} from '@mdx-js/react'
import Home from './pages/Home'
import Guides from './pages/Guides'

router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  }
  {
    path: "/guides/*"
    element: <Guides />
  }
])

components =
  h1: ({children}) -> <h1 className='py-6 text-6xl font-bold'>{children}</h1>
  h2: ({children}) -> <h1 className='py-6 text-5xl font-bold'>{children}</h1>
  h3: ({children}) -> <h1 className='py-6 text-4xl font-bold'>{children}</h1>
  h4: ({children}) -> <h1 className='py-6 text-3xl font-bold'>{children}</h1>
  h5: ({children}) -> <h1 className='py-6 text-2xl font-bold'>{children}</h1>
  h6: ({children}) -> <h1 className='py-6 text-1xl font-bold'>{children}</h1>
  p: ({children}) -> <p className='py-3'>{children}</p>
  img: ({alt, src}) ->
    if alt is 'logo'
      <img src={src} width='250px' />
    else
      <img src={src} />

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MDXProvider components={components}>
      <RouterProvider router={router} />
    </MDXProvider>
  </React.StrictMode>
  )
