# BBEEEE.MOBI

##### Connected Together, We Are Infinite!

![](https://image.bbeeee.cloud/bbeeee.mobi/bbeeee-mobi-infinite.png)

Launch your own mobile platform by just upload your brand pictures and introduction.

Relying on the cloud computing resources of BBEEEE.Cloud, BBEEEE.Mobi provides users with file storage, uploading, downloading, scheduled task execution, background task execution, application and website hosting, user database storage, query and maintenance.

At the same time, more and more various third-party services and tools are integrated and connected with BBEEEE.Cloud.

For example:

![logo](https://image.bbeeee.cloud/ftp.mn/logo.png)

FTP.MN provides users with FTP file transfer protocol based on the BBEEEE.Cloud, so that users can achieve team collaboration, file upload, configuration file update, and content update services in a simple and fast way.

![logo](https://image.bbeeee.cloud/auth.mn/logo.png)

AUTH.MN provides users with a single authentication and login interface, including: user mobile phone number, email address, credit card, basic information authentication, cross-website, cross-application, and cross-device single sign-on service.

![logo](https://image.bbeeee.cloud/bpp.la/logo.png)

BPP.LA provides micro-app services that implements a single feature. It can be easily integrated into existing apps, since many apps and services have some common features such as file upload, email verification and image cropping, which will bringing users a better consistent experience and avoiding unnecessary duplication of development.

![logo](https://image.bbeeee.cloud/bee.run/logo.png)

BEE.RUN based on similar thinking with bpp, in addition to the user-visible interactive functions, there are some tasks performed in the background that also exist widely in different applications and services. Unifying these tasks on one platform will also greatly improve user experience and development efficiency.

![logo](https://image.bbeeee.cloud/bbeeee.net/logo.png)

BBEEEE.Net is the infrastructure for all the instantly message apps. It can relay various messages and data with unlimited bandwidth and capacity.
